/* lite-green: 8DC63F 
	gark-green: 599641 */

	body {
		margin: 0;
		font-family: 'Source Serif Pro', serif !important;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		background: rgb(255, 248, 248) 0% 0% no-repeat padding-box !important;
	}
	
	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: 'Source Serif Pro', serif !important;
	}
	
	.ant-select-dropdown ::-webkit-scrollbar {
		width: 13px !important;
	}
	body ::-webkit-scrollbar {
		width: 15px;
	}
	
	/* body ::-webkit-scrollbar-track {
						background: rgb(101 147 99);
						border-radius: 0;
					} */
	h3.ant-typography,
	.ant-typography h3 {
		margin-bottom: 0px;
		font-size: 20px;
		line-height: 1;
	}
	.ant-table-header.ant-table-hide-scrollbar {
		margin-bottom: 0px !important;
		/* overflow-x: hidden !important; */
	}
	
	.home-grid-col-3 > h3 {
		text-align: center;
	}
	
	.ant-modal {
		overflow-x: hidden !important;
		overflow-y: auto;
		/* padding-bottom: 0px !important; */
	}
	
	.ant-modal-body {
		padding: 5px 20px 5px 20px;
	}
	
	.ant-modal-close svg {
		margin: 20px !important;
	}
	
	.ant-layout {
		background: none;
	}
	.ant-layout-header {
		height: 70px;
		padding: 0 0px;
		color: rgba(0, 0, 0, 0.65);
		line-height: 64px;
		background: none;
	}
	
	.ant-layout-content {
		margin-left: 30px;
		margin-right: 30px;
	}
	
	.ant-layout-sider {
		background: #1e2f10;
	}
	.ant-layout-sider-trigger {
		background: #2a4217;
	}
	
	.ant-form-item-label > label.ant-form-item-required::before {
		display: none;
	}
	
	.space-table-head {
		padding: 8px 0px;
		position: relative;
	}
	
	.ant-space-item {
		margin-right: 0px !important;
	}
	
	.date-range-tables {
		font-size: 13px !important;
		height: 25px !important;
		width: 220px;
		margin: 0px 5px;
	}
	
	.ant-btn-round{
		height: 25px;
	}
	
	/* Date Range picker rsuite //////////////////////////*/
	.rs-picker-menu {
		z-index: 1001;
	}
	.rs-picker-toolbar-ranges {
		display: none;
	}
	
	.rs-picker-default .rs-picker-toggle.rs-btn-xs {
		border-radius: 20px;
		font-size: 13px;
		line-height: unset;
	}
	
	.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
	.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
		color: black;
		font-size: 16px;
	}
	
	.rs-picker-default .rs-picker-toggle {
		height: 25px;
	}
	.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
	.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
	.rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
	.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
		border-color: #3e8a00;
	}
	
	.rs-calendar-table-cell-in-range::before {
		background-color: rgb(178 255 89 / 82%);
	}
	.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
		color: #ffffff;
		border-color: #8bc34a;
		background-color: #8bc34a;
	}
	
	.rs-picker-toolbar-right-btn-ok:active:hover,
	.rs-picker-toolbar-right-btn-ok.rs-btn-active:hover,
	.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:hover,
	.rs-picker-toolbar-right-btn-ok:active:focus,
	.rs-picker-toolbar-right-btn-ok.rs-btn-active:focus,
	.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok:focus,
	.rs-picker-toolbar-right-btn-ok:active.focus,
	.rs-picker-toolbar-right-btn-ok.rs-btn-active.focus,
	.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok.focus,
	.rs-picker-toolbar-right-btn-ok:not(.rs-btn-disabled):hover,
	.rs-picker-toolbar-right-btn-ok:active,
	.rs-picker-toolbar-right-btn-ok.rs-btn-active,
	.rs-open > .rs-dropdown-toggle.rs-picker-toolbar-right-btn-ok,
	.rs-picker-toolbar-right-btn-ok,
	.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled,
	.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover,
	.rs-picker-toolbar-right-btn-ok.rs-picker-toolbar-btn-disabled:hover:active {
		background-color: #8bc34a;
	}
	.rs-picker-daterange-header {
		display: none;
	}
	
	.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
		border: 1px solid #13ad00;
	}
	
	/* ////////////////////////////////////////////// */
	
	.ant-input-group-wrapper {
		width: 160px !important;
	}
	.btn-primary {
		background: transparent linear-gradient(180deg, #8dc63f 0%, #599641 100%)
			no-repeat padding-box !important;
		box-shadow: 0px 3px 6px #00000085;
		border-radius: 85px;
		color: #fff9f9;
		transition: all 0.2s;
		border: none;
		margin: 6px;
		font-family: 'Source Serif Pro', serif !important;
	}
	
	.btn-primary:hover {
		background: transparent linear-gradient(180deg, #81b439 0%, #528b3b 100%)
			no-repeat padding-box !important;
		transform: translateY(-3px);
		box-shadow: 5px 6px 8px #00000085;
		color: white;
	}
	
	.btn-primary::after,
	.navBar-avatar::after,
	.btn-profile::after {
		content: '';
		display: inline-block;
		height: 100%;
		width: 100%;
		border-radius: 5rem;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: all 0.4s;
	}
	.btn-primary:hover::after,
	.navBar-avatar:hover::after,
	.btn-profile:hover::after {
		transform: scaleX(1.4) scaleY(1.6);
		opacity: 0;
	}
	
	.btn-primary:active,
	.ui .button:focus,
	.navBar-avatar:active,
	.btn-profile:active {
		outline: none;
		transform: translateY(-1px);
		box-shadow: 0 0.5rem 1rem #0000005e;
		color: white;
	}
	
	.btn-danger,
	.btn-danger:focus,
	.btn-danger:active {
		background: #c53c3e !important;
		box-shadow: 0px 3px 6px #00000085;
		border-radius: 85px;
		color: #fff9f9;
		transition: all 0.2s;
		border: none;
		margin: 10px;
		font-family: 'Source Serif Pro', serif !important;
	}
	
	.btn-danger:hover {
		background: #9d3031 !important;
		color: white;
	}
	.btn-blue,
	.btn-blue:focus,
	.btn-blue:active {
		background-color: rgb(35, 66, 84);
		border-radius: 20px;
		box-shadow: 0px 3px 6px #00000085;
		color: white;
		transition: all 0.2s;
		border: none;
		margin: 10px;
		font-family: 'Source Serif Pro', serif !important;
	}
	.btn-blue:hover {
		background: rgb(24, 45, 58) !important;
		color: white;
	}
	.btn-group-section {
		border: none;
		margin-bottom: 5px;
		margin-right: 6px;
	}
	
	/* .ant-btn[disabled],
					.ant-btn[disabled]:hover,
					.ant-btn[disabled]:focus,
					.ant-btn[disabled]:active {
						color: rgba(0, 0, 0, 0.25) !important;
						background: #f5f5f5 !important;
						border-color: #d9d9d9 !important;
						text-shadow: none !important;
						-webkit-box-shadow: none !important;
						box-shadow: none !important;
					} */
	
	.loader-placeholder {
		text-align: center;
		background: white;
		width: 100vw;
		height: 100vh;
		padding-top: 40vh;
		justify-content: center;
		position: relative;
		z-index: 3000;
		font-size: 25px;
	}
	
	.ant-form-item-has-error .ant-form-item-explain,
	.ant-form-item-has-error .ant-form-item-split {
		color: #560f0f;
	}
	
	.bold-flags {
		text-align: center;
		font-size: 10px;
		font-weight: 700;
		font-style: italic;
	}
	
	.switch-in-tables.ant-switch,
	.ant-switch-checked.switch-in-tables {
		background-color: #234254 !important;
	}
	
	.ant-form-item-has-warning .ant-input {
		border-color: #d9d9d9;
	}
	
	/* ANTD RADIO BUTTONS////////////////////////////////////// */
	
	.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
		border-right-color: #1e2f10;
	}
	
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
		border-color: #1e2f10;
		border-bottom-left-radius: 20px;
		border-top-left-radius: 20px;
	}
	
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		color: #1e2f10;
		border-color: #1e2f10;
	}
	
	.ant-radio-button-wrapper:last-child {
		border-bottom-right-radius: 20px;
		border-top-right-radius: 20px;
	}
	
	.ant-radio-button-wrapper:first-child {
		border-bottom-left-radius: 20px;
		border-top-left-radius: 20px;
	}
	
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
		background-color: #1e2f10;
	}
	.ant-radio-group-solid
		.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
		color: #fff;
		background: #1e2f10;
		border-color: #1e2f10;
	}
	
	.ant-radio-group-solid
		.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		color: #fff;
		background: #1e2f10;
		border-color: #1e2f10;
	}
	
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		color: #1e2f10;
		border-color: #1e2f10;
	}
	
	.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
		border-right-color: #1e2f10;
	}
	
	.ant-radio-group-solid
		.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
		color: #fff;
		background: #1e2f10;
		border-color: #1e2f10;
	}
	
	.ant-radio-group-solid
		.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		color: #fff;
		background: #1e2f10;
		border-color: #1e2f10;
	}
	
	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
		color: #1e2f10;
		border-color: #1e2f10;
	}
	
	.ant-radio-button-wrapper:hover {
		position: relative;
		color: #1e2f10;
	}
	
	/* td .ant-form-item-control-input-content {
						text-align-last: center;
					} */
	
	table .ant-checkbox-inner {
		border: 1px solid black !important;
		background-color: #f44336;
	}
	
	table .ant-checkbox-wrapper:hover .ant-checkbox-inner,
	table .ant-checkbox:hover .ant-checkbox-inner,
	table .ant-checkbox-input:focus + .ant-checkbox-inner {
		border-color: #6fa218;
	}
	
	table .ant-checkbox-checked .ant-checkbox-inner {
		background-color: #6fa218;
		border-color: #6fa218;
	}
	/* /////////////////////Tables///////////////////////////////////// */
	/* TABLE IN EXPANDED ROW IN CLAIMS BY DETAILS IN INSURANCE PORTAL  */
	
	.elDocDetailsTable .ant-table-thead > tr > th,
	.ant-table-tbody > tr > td,
	.ant-table tfoot > tr > th,
	.ant-table tfoot > tr > td {
		padding: 0px;
	}
	
	.elDocDetailsTable .ant-table-thead > tr > th {
		color: rgb(0 0 0);
		background: #d2d0d0;
		font-weight: bold;
		font-size: 12px;
		border-bottom: 1px solid black;
		border-top: 1px solid black;
		border-left: 1px solid black;
	}
	
	.elDocDetailsTable .ant-table {
		background-color: white;
	}
	
	.elDocDetailsTable .ant-table-tbody > tr > td {
		border-bottom: 1px solid black;
		border-left: 1px solid black;
	}
	
	.elDocDetailsTable .ant-table-tbody > tr.ant-table-row:hover > td {
		background: #cfcfcf;
		border-left: 1px solid rgb(0, 0, 0) !important;
	}
	
	/* /////////Normal Table///////////////////////////////////////////////////// */
	.hidden-text-in-table {
		content-visibility: hidden !important ;
	}
	.ant-table {
		overflow-x: scroll;
		overflow-y: hidden;
		line-height: 1.3;
		background-color: rgb(190 219 141);
		font-size: 15px;
		font-weight: 600;
	}
	.amb-table .ant-table-container {
		max-height: 70vh;
		overflow-y: scroll;
	}
	
	.ant-table-thead th.ant-table-column-sort,
	.ant-table-thead th.ant-table-column-has-sorters:hover {
		background: #1e2f10;
	}
	
	.ant-table-thead > tr > th {
		color: rgb(255 255 255);
		font-weight: 600;
		background: #1e2f10;
		font-size: 13px;
		text-align-last: center;
		text-align: -webkit-center;
	}
	
	td.ant-table-column-sort {
		background: #bedb8d;
	}
	
	tr.ant-table-expanded-row > td,
	tr.ant-table-expanded-row:hover > td {
		background-color: rgb(190 219 141);
	}
	
	.ant-table-tbody > tr.ant-table-row:hover > td {
		cursor: pointer;
	}
	
	.ant-table-thead > tr > th,
	.ant-table-tbody > tr > td,
	.ant-table tfoot > tr > th,
	.ant-table tfoot > tr > td {
		padding: 1px;
		border-left: 1px solid white;
	}
	
	.ant-table-tbody > tr > td {
		color: #424141;
		border-bottom: 1px solid white;
	}
	
	.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table {
		margin: 0 !important;
	}
	
	.ant-table-pagination.ant-pagination {
		margin-right: 20px;
	}
	
	.ant-table-row-level-0 {
		font-size: 13px;
		color: black;
		font-weight: 600;
	}
	
	.ant-modal .ant-table-row-level-0 {
		font-weight: 400 !important;
		font-size: 14px;
	}
	.procedures-table .ant-table-row-level-0 {
		font-size: 15px;
	}
	
	.ant-modal .ant-table .ant-empty-normal {
		display: none !important;
	}
	
	.ant-table-tbody > tr.ant-table-row:hover > td {
		background: #fafafa;
		border-left: 1px solid rgb(151, 151, 151) !important;
	}
	
	.ant-table-row-level-1 {
		font-size: 12px;
		background: #96c743;
	}
	
	.procedures-table .ant-table-row-level-1 {
		font-size: 14px;
		background: #a2d64b;
	}
	
	.ant-table-row-level-2 {
		font-size: 12px;
		background: #6fa218;
	}
	
	.ant-table-row-level-1 > .ant-table-cell {
		padding-left: 10px;
	}
	.ant-table-row-level-1 > .ant-table-cell > .eye-icon {
		margin-left: -10px !important;
	}
	
	.ant-table-row-level-2 > .ant-table-cell {
		padding-left: 20px;
	}
	
	.ant-table-row-selected > td,
	.ant-table-row-selected > td > div > .ant-btn-link {
		background: #1b3c00 !important;
		color: white !important;
		border-left: 1px solid white !important;
	}
	
	.table-div-centered {
		text-align: center !important;
		font-size: 13px !important;
		text-align-last: center !important;
	}
	.ant-modal .table-div-centered {
		font-size: 14px !important;
	}
	.ant-table-column-sorters {
		padding: 5px 3px;
	}
	
	.ant-table-footer {
		padding: 6px 0px;
		background: #669462;
		text-align: end;
		position: sticky;
	}
	
	.ant-table-footer > .detail-table-footer {
		padding: 1px 1px;
		background: #fafafa !important;
		text-align: unset;
		position: unset;
		text-align-last: end;
	}
	
	.ant-table.ant-table-bordered > .ant-table-footer {
		background: #fafafa !important;
	}
	
	.ant-table-row-expand-icon.ant-table-row-expand-icon-expanded {
		color: #000 !important;
	}
	
	.ant-table-row-level-0,
	.ant-pagination {
		font-size: 13px;
	}
	
	.ant-table-pagination.ant-pagination {
		margin-right: 0px;
	}
	
	.ant-table-pagination.ant-pagination {
		margin: 1px 0;
	}
	
	.ant-pagination-prev,
	.ant-pagination-next,
	.ant-pagination-jump-prev,
	.ant-pagination-jump-next {
		min-width: 18px;
		height: 18px;
		line-height: 18px;
		margin-right: 2px;
	}
	
	.ant-pagination-item {
		min-width: 18px;
		height: 18px;
		line-height: 17px;
		margin-right: 2px;
	}
	
	.ant-pagination-jump-prev
		.ant-pagination-item-container
		.ant-pagination-item-ellipsis,
	.ant-pagination-jump-next
		.ant-pagination-item-container
		.ant-pagination-item-ellipsis {
		letter-spacing: 0px;
	}
	.ant-pagination.ant-table-pagination .ant-select-selector {
		height: 20px !important;
		font-size: 12px !important;
	}
	
	.ant-pagination-options {
		vertical-align: unset;
	}
	
	.ant-pagination-options-quick-jumper {
		line-height: unset;
	}
	
	.ant-pagination-prev .ant-pagination-item-link,
	.ant-pagination-next .ant-pagination-item-link,
	.ant-pagination-item {
		border-radius: 10px !important;
	}
	
	.ant-list-pagination
		.ant-select.ant-pagination-options-size-changer.ant-select-single.ant-select-show-arrow {
		display: none;
	}
	/* Fixed header table /////////////////////////////////////////////////////////////////////*/
	
	.table-fixed-header-div {
		overflow-y: auto;
	}
	
	.table-fixed-header-th {
		border: 1px solid;
		position: sticky;
		top: -1px;
		font-size: 12px;
		background: #d2d0d0;
		box-shadow: inset 0px 1px #000, 0 1px #000;
		z-index: 1000;
	}
	.table-fixed-header-div ::-webkit-scrollbar {
		width: 12px !important;
	}
	
	.scroll-table-service-correlations .ant-table-header > table {
		visibility: inherit !important;
	}
	
	.servise-correlation-table .ant-table-body {
		overflow-y: scroll;
		max-height: calc(100vh - 460px);
	}
	
	/* /////////////////////////////////////////////////////////////////////////////////// */
	
	/* React-Grid-Table Styled !!!////////////////////////////////////////////////////////// */
	
	.react-grid-Main {
		outline: none;
	}
	
	.labRes-table-grid .react-grid-Grid,
	.ambSheets-table-grid .react-grid-Grid {
		min-height: calc(100vh - 210px) !important;
	}
	
	.ins-claim-table-grid .react-grid-Grid {
		min-height: calc(100vh - 205px) !important;
	}
	.react-grid-Canvas {
		height: 100% !important;
		background-color: #bedb8d !important;
	}
	
	.react-grid-Header {
		color: rgb(255 255 255);
		font-weight: 600;
		background: #1e2f10 !important;
		font-size: 13px;
		text-align-last: center;
		text-align: -webkit-center;
	}
	
	.react-grid-HeaderCell {
		background: #1e2f10 !important;
		font-weight: 600 !important;
		white-space: break-spaces !important;
		padding: 0px !important;
		padding-top: 10px !important;
		display: block !important;
	}
	
	.react-grid-Cell--frozen {
		z-index: 1;
	}
	
	.react-grid-Cell--frozen:focus {
		z-index: 1;
	}
	
	.react-grid-Cell:not(.editing) .react-grid-Cell__value {
		overflow-wrap: break-word !important;
		white-space: break-spaces !important;
		line-height: 1.2 !important;
	}
	.react-grid-Cell:hover {
		background: rgb(22, 104, 14);
		color: ivory;
	}
	.react-grid-Row.row-hover .react-grid-Cell {
		background-color: #155804;
	}
	.react-grid-Row:hover .react-grid-Cell,
	.react-grid-Row.row-context-menu .react-grid-Cell {
		background: #fafafa !important;
		border-color: rgb(151, 151, 151) !important;
	}
	
	.react-grid-Cell {
		background-color: #bedb8d !important ;
		font-size: 13px !important;
		color: #424141 !important;
		font-weight: 600;
		padding: 0px !important;
	}
	
	.rdg-selected {
		border: none !important;
	}
	
	.react-grid-Main .ant-empty {
		text-align: center;
		padding: 202px;
		background: #bedb8d;
		margin: 0px !important;
		height: calc(100vh - 210px);
	}
	
	.react-grid-Cell-selected {
		background: #1b3c00 !important;
		color: ivory !important;
	}
	
	.react-grid-Row:hover .react-grid-Cell-selected,
	.react-grid-Row.row-context-menu .react-grid-Cell-selected {
		background: #1b3c00 !important;
		color: ivory !important;
	}
	
	/*//////SideBar///////////////////////// */
	.logo-placeholder {
		height: 80px;
		overflow: hidden;
		margin: 0;
		padding: 0;
		text-align-last: center;
		border-bottom: 1px solid #5a5a5a;
		background: rgb(30 47 16);
	}
	
	.logo {
		width: 180px;
		margin: 8px;
	}
	
	.sidebar {
		width: 250px;
		height: 100vh;
		box-shadow: 0px 8px 18px #9ecc41;
		transform: translate(all, 0.5s);
	}
	
	.ant-menu.sidebar.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-root.ant-menu-vertical {
		width: 60px !important;
	}
	.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
		background-color: #dcdcdc59;
	}
	
	.ant-menu.ant-menu-dark,
	.ant-menu-dark,
	.ant-menu.ant-menu-dark {
		background: rgb(30 47 16 / 1);
		color: white;
	}
	
	.ant-menu-dark .ant-menu-inline.ant-menu-sub {
		background: rgb(20 27 11) !important;
		color: white;
	}
	
	.ant-menu-dark.ant-menu-inline .ant-menu-item,
	.ant-menu-dark.ant-menu-vertical .ant-menu-item,
	.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
	.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
		color: white;
		font-size: 17px;
	}
	
	.ant-menu-item,
	.ant-menu-submenu-title {
		padding-left: 20px !important;
		font-size: 17px;
		height: 60px !important;
		border-bottom: 1px solid #5a5a5a !important;
		padding-top: 23px !important;
		margin: 0 !important;
	}
	
	.ant-menu-submenu-arrow {
		margin-top: 15px;
	}
	
	.ant-menu-item .anticon,
	.ant-menu-submenu-title .anticon {
		font-size: 25px;
	}
	
	.ant-menu-item .anticon + span,
	.ant-menu-submenu-title .anticon + span {
		float: right;
	}
	
	.ant-menu-item.ant-menu-item-only-child,
	.ant-menu-submenu-title {
		background: #1e2f10;
	}
	.ant-menu-item.ant-menu-item-only-child:hover,
	.ant-menu-submenu-title:hover {
		background: #1e2f10;
	}
	.ant-menu.ant-menu-dark,
	.ant-menu-dark .ant-menu-sub,
	.ant-menu.ant-menu-dark .ant-menu-sub {
		background: #1e2f10;
	}
	
	.ant-menu-inline-collapsed-tooltip {
		display: none !important;
		pointer-events: none;
	}
	
	/* /////////////////// Drawer //////////////*/
	
	.ant-drawer-body {
		padding: 0px;
		background: #1e2f10;
	}
	
	.am-navbar {
		margin-bottom: 50px;
		background-color: rgba(158, 204, 65, 1) !important;
	}
	
	.ant-menu-item {
		color: white;
		font-size: 14px;
	}
	
	.ant-dropdown-link {
		font-size: 16px;
		cursor: pointer;
		margin: 10px;
		width: 200px;
	}
	
	.ant-dropdown-menu-item:hover,
	.ant-dropdown-menu-submenu-title:hover {
		background-color: #b3b3b3;
	}
	.drawer-avatar {
		background-color: #032f03;
		margin: 10px;
		box-shadow: 3px 4px 9px #0000008c;
	}
	
	.ant-menu.ant-menu-dark,
	.ant-menu-dark .ant-menu-sub,
	.ant-menu.ant-menu-dark .ant-menu-sub {
		height: fit-content;
	}
	
	/*//////NavBar///////////////////////// */
	
	.navBar {
		background-color: rgba(158, 204, 65, 1) !important;
		box-shadow: 26px 4px 29px #00000029;
		width: 100%;
		height: 60px;
	}
	
	.navBarItems {
		display: -moz-inline-box;
		float: right;
		margin-right: 30px;
	}
	
	.companyName-div {
		display: inline-block;
		max-width: 240px;
		line-height: 12px;
		font-size: 13px;
		font-weight: bold;
	}
	
	.document-type-div {
		display: inline-block;
		max-width: 240px;
		line-height: 12px;
		font-size: 16px;
		font-weight: bold;
		margin-left: 20px;
	}
	
	.navBar-badge {
		margin: 10px;
	}
	
	.navBar-icon {
		font-size: 23px;
		margin-top: 0px !important;
		background: #032f03;
		color: white;
	}
	
	.navBar-avatar {
		background-color: #032f03;
		margin: 10px;
		box-shadow: 3px 4px 9px #0000008c;
		transition: all 0.2s;
	}
	
	.navBar-avatar:hover,
	.navBar-icon:hover {
		transform: translateY(-3px);
	}
	
	/*  ////////////////////// !!!Inputs style/////////////// */
	.ant-input-affix-wrapper:hover {
		border-color: #4caf50a6;
	}
	
	.ant-input-affix-wrapper:focus,
	.ant-input-affix-wrapper-focused {
		border-color: #09630d54;
		box-shadow: 0 0 0 2px rgb(19 86 37 / 19%);
	}
	
	/* /////////////////////////////////////////////////////// */
	
	.ant-badge-count {
		-webkit-box-shadow: 0 0 0 1px #8bc34a73;
		box-shadow: 0 0 0 1px #8bc34a73;
	}
	
	.ant-dropdown-link {
		font-size: 16px;
		color: black;
		cursor: pointer;
		margin: 10;
	}
	
	/*//////HomeGrid///////////////////////// */
	
	.home {
		position: absolute;
	}
	
	.home-grid-avatar {
		background-color: #032f03;
	}
	
	.ant-row-center {
		text-align: -webkit-center;
	}
	
	.home-grid-col-1 {
		border-radius: 20px;
		background: transparent linear-gradient(180deg, #8dc63f 0%, #599641 100%) 0%
			0% no-repeat padding-box;
		box-shadow: 9px 9px 6px #00000029;
		text-align: center;
		text-align: -webkit-center;
		max-width: 400px;
		min-height: 210px;
		font-size: 20px;
		color: #021b02;
	}
	
	.home-grid-col-1:hover {
		background: transparent linear-gradient(180deg, #81b439 0%, #528b3b 100%) 0%
			0% no-repeat padding-box;
	}
	
	.home-grid-col-1:hover .ant-statistic-title {
		color: rgb(255, 248, 248) !important;
	}
	
	.home-grid-col-2 {
		height: fit-content;
		border-radius: 20px;
		background: transparent linear-gradient(180deg, #8dc63f 0%, #599641 100%) 0%
			0% no-repeat padding-box;
		box-shadow: 9px 9px 6px #00000029;
		padding: 20px;
	}
	.home-grid-col-3 {
		height: fit-content;
		border-radius: 20px;
		background: transparent linear-gradient(180deg, #8dc63f 0%, #599641 100%) 0%
			0% no-repeat padding-box;
		box-shadow: 9px 9px 6px #00000029;
		padding: 20px;
	}
	.ant-avatar.ant-avatar-icon {
		margin-top: 10px;
	}
	.ant-statistic {
		margin-top: 20px;
	}
	
	.ant-statistic-title {
		font-size: 20px;
		color: #021b02;
	}
	
	.ant-statistic-content {
		font-size: 32px;
	}
	
	.ant-btn-link,
	.ant-btn-link:hover,
	.ant-btn-link:active,
	.ant-btn-link:visited,
	.ant-btn-link:focus {
		font-size: 13px;
		font-weight: 600;
		color: rgb(78, 75, 75);
		font-family: 'Source Serif Pro', serif !important;
	}
	
	/* /////////////////////////////////// AccountManager///////////////*/
	
	.accountManger-placeholder {
		border: none !important;
		background: transparent !important;
		box-shadow: none !important;
	}
	
	/* //////////////////////!!!Input  styled!!!///////////////// */
	.ant-form-item textarea.ant-input {
		max-width: 80vw;
		float: right;
	}
	.ant-input-group > .ant-input:first-child,
	.ant-input-group-addon:first-child {
		height: 25px;
	}
	.ant-btn .ant-input-search-button .ant-btn-primary {
		height: 25px;
	}
	.ant-input-group-addon > .ant-btn {
		height: 25px;
	}
	.ant-input-group-wrapper {
		width: 220px;
		height: 25px;
		float: right;
	}
	
	.ant-input,
	.ant-picker-input > input {
		font-size: 15px;
		color: black;
		font-family: 'Source Serif Pro', serif !important;
	}
	
	.ant-picker,
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
	.ant-input-number {
		border: 1px solid #8c8181 !important;
	}
	
	.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
		border-color: #64911b;
	}
	
	.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
	.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
	.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
		background: #64911b;
	}
	
	.ant-picker-time-panel-column
		> li.ant-picker-time-panel-cell-selected
		.ant-picker-time-panel-cell-inner {
		background: #cfff97;
	}
	
	.ant-picker-now-btn {
		color: #7fb525 !important ;
	}
	
	input[type='text'],
	input[type='number'],
	input[type='email'],
	textarea,
	.ant-input-affix-wrapper {
		border-radius: 20px;
		padding: 0px 10px;
		border: 1px solid #8c8181;
		height: 25px;
	}
	
	.ant-input:focus,
	.ant-select:focus,
	.ant-input-focused,
	.ant-picker-focused,
	.ant-input-affix-wrapper:focus,
	.ant-input-affix-wrapper-focused,
	.ant-select-focused.ant-select-multiple .ant-select-selector,
	.ant-picker:hover,
	.ant-input:hover,
	.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
	.ant-form-item-has-warning .ant-input:focus,
	.ant-form-item-has-warning .ant-input:hover,
	.ant-input-affix-wrapper:hover {
		border-color: #64911b;
		box-shadow: 0 0 0 2px #64911b;
	}
	
	.scroll-table-service-correlations
		.ant-select.ant-select-focused.ant-select-single {
		border-color: #64911b;
		box-shadow: 0 0 0 2px #64911b;
	}
	
	input[type='password']:hover {
		border-color: transparent;
		box-shadow: 0 0 0 2px transparent;
	}
	
	.ant-switch-checked {
		background-color: #7fb525;
	}
	
	.ant-input[disabled],
	.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
		.ant-select-selector {
		color: black;
	}
	
	.ant-picker {
		height: 25px;
		font-size: 20px;
		border-radius: 20px;
	}
	
	.ant-input-number {
		text-align-last: center;
		width: auto;
	}
	/* ///////////////////////////////////////////// */
	
	.btn-groupe {
		box-shadow: 0px 3px 6px #00000085;
		border-radius: 85px;
		color: #fff9f9;
		transition: all 0.2s;
		border: none;
		font-size: 12px;
		height: 25px;
		font-family: 'Source Serif Pro', serif !important;
		margin: 0px 3px;
	}
	.btn-groupe:hover {
		background: transparent linear-gradient(180deg, #81b439 0%, #528b3b 100%)
			no-repeat padding-box;
		transform: translateY(-3px);
		box-shadow: 5px 6px 8px #00000085;
		color: white;
	}
	
	.btn-groupe::after {
		content: '';
		display: inline-block;
		height: 100%;
		width: 100%;
		border-radius: 5rem;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: all 0.4s;
	}
	.btn-groupe:hover::after {
		transform: scaleX(1.4) scaleY(1.6);
		opacity: 0;
	}
	
	.btn-groupe:active,
	.ui .button:focus {
		outline: none;
		transform: translateY(-1px);
		box-shadow: 0 0.5rem 1rem #0000005e;
		color: white;
	}
	
	.ant-form-item-label > label {
		font-size: 15px;
	}
	
	.ant-modal-title {
		font-size: 18px;
		padding: 0px 14px;
	}
	
	/* //////////////////  Profile page////////////////  */
	.ant-btn {
		padding: 3px 9px !important;
		line-height: 1.1;
	}
	.ant-btn-primary,
	.ant-input-group-addon {
		border-top-right-radius: 80px;
		border-bottom-right-radius: 80px;
	}
	
	.ant-btn-primary,
	.ant-btn-primary:focus,
	.ant-btn-primary:active {
		background: #1e2f10;
		border: #1e2f10;
		text-align-last: center;
	}
	
	.ant-btn-primary:hover {
		background: #395620;
	}
	
	.ant-btn-primary:disabled {
		background: #e0e0df !important;
	}
	
	.ui.grid > [class*='very relaxed'].row > .column,
	.ui[class*='very relaxed'].grid > .column:not(.row),
	.ui[class*='very relaxed'].grid > .row > .column {
		text-align-last: center;
	}
	
	.profilePage-placeholder {
		text-align: center;
	}
	
	.btn-profile-group {
		display: inline-block;
	}
	
	.btn-profile {
		font-size: 20px;
		width: 160px;
		height: 70px;
		border-radius: 20px;
		margin: 20px;
		box-shadow: 4px 6px 20px 0px #00000075;
		transition: all 0.2s;
	}
	
	.btn-profile:hover {
		transform: translateY(-5px);
	}
	
	.password-form-message {
		font-size: 15px;
		margin-bottom: 21px;
		color: black;
		font-weight: bold;
	}
	
	.success-messages {
		font-size: 15px;
		font-weight: 600;
		color: black;
	}
	
	/* //////////////InsuranceStatus Page////////////////////////////////////// */
	
	.status-grid-col {
		padding: 15px;
		border-radius: 20px;
		background: transparent linear-gradient(180deg, #8dc63f 0%, #599641 100%)
			no-repeat padding-box !important;
		box-shadow: 0px 3px 6px #00000085;
	}
	
	.status-grid-col-1 {
		padding: 30px;
		border-radius: 20px;
		background: transparent linear-gradient(180deg, #8dc63f 0%, #599641 100%)
			no-repeat padding-box !important;
		box-shadow: 0px 3px 6px #00000085;
		max-width: 600px;
	}
	/* ///////////////////////////////// !!!! Inputs Styled////////////////////////////// */
	/* ////////////////Selector Input styled//////////////////////////////////////// */
	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		height: 25px;
		border-radius: 20px;
		font-size: 14px;
		color: black;
	}
	
	.home-grid-col-3
		.ant-select-single:not(.ant-select-customize-input)
		.ant-select-selector {
		height: 25px;
	}
	
	.ant-select-item-option-content {
		color: black;
		font-size: 14px;
	}
	
	/* ////////////////////////////////////////////////////////////////  */
	
	.ant-radio-inner {
		width: 20px;
		height: 20px;
	}
	
	.ant-radio-inner::after {
		top: 4px;
		left: 4px;
		width: 10px;
		height: 10px;
	}
	
	.ant-form label {
		font-size: 17px;
		color: #000000b7;
	}
	
	.ant-form-item-control {
		text-align-last: left;
	}
	
	.am-list-item .am-list-line .am-list-extra {
		flex-basis: 100% !important;
		color: #000 !important;
	}
	.am-list-item {
		border-radius: 20px !important;
		border: 1px solid #00000030 !important;
		height: 32px !important;
		min-height: 32px !important;
		text-align: -webkit-left;
		width: 100% !important;
	}
	
	/* ////////////////////////////////////////////////////////////////////// */
	
	/* ///////////////////////////Create Appointment Page/////////// */
	
	.ant-select-multiple .ant-select-selector {
		display: block !important;
	}
	
	.ant-select-multiple .ant-select-selection-search {
		margin-left: 0 !important;
	}
	
	.ant-select-tree
		.ant-select-tree-treenode-disabled
		.ant-select-tree-node-content-wrapper {
		color: rgba(0, 0, 0, 0.7);
	}
	.ant-select-tree,
	.ant-select {
		color: black;
	}
	
	.appointment-modal {
		width: 95% !important;
	}
	
	.issuer-form-item {
		display: inline-block;
		border: 1px solid #d9d9d9;
		border-radius: 10px;
		padding: 5px;
		width: 49%;
		margin: 5px;
		margin-right: 0;
	}
	
	.patient-form-item {
		display: inline-block;
		border: 1px solid #d9d9d9;
		border-radius: 10px;
		padding: 5px;
		width: 50%;
		margin: 5px;
		margin-left: 0px;
	}
	
	.uin-input {
		display: inline-block;
		width: 20%;
		margin: 5px;
	}
	
	.docName-input {
		display: inline-block;
		width: 77%;
		margin: 5px;
		margin-right: 0px;
	}
	
	.spec-input {
		display: inline-block;
		width: 99%;
		margin: 5px;
	}
	
	.card-input {
		display: inline-block;
		width: 47%;
		margin: 5px;
		margin-right: 0px;
	}
	.email-input {
		display: inline-block;
		width: 50%;
		margin: 5px;
	}
	.pacName-input {
		display: inline-block;
		width: 77%;
		margin: 5px;
		margin-right: 0px;
	}
	
	.egn-input {
		display: inline-block;
		width: 20%;
		margin: 5px;
	}
	.prior-approval-modal .patient-form-item {
		display: flex !important;
		border: none !important;
		border-radius: 0 !important;
		padding: 0 !important;
		width: auto !important;
		margin: 0 !important;
	}
	.prior-approval-modal .egn-input {
		display: inline-block !important;
		width: calc(15% - 8px) !important;
		margin: 0 !important;
	}
	.prior-approval-modal .pacName-input {
		display: inline-block !important;
		width: calc(50% - 8px) !important;
		margin: 0 8px !important;
	}
	.prior-approval-modal .email-input {
		display: inline-block !important;
		width: calc(35% - 8px) !important;
		margin: 0 !important;
	}
	
	.mkbCode-input {
		display: none !important;
		width: calc(20% - 8px) !important;
		margin: 0 8px !important;
	}
	
	.mkbName-input {
		display: inline-block !important;
		width: 100% !important;
		margin: 0 !important;
	}
	
	.docNumber-input {
		display: inline-block !important;
		width: calc(10% - 8px) !important;
	}
	
	.docDate-input {
		display: inline-block !important;
		width: calc(17% - 8px) !important;
		margin: 0 8px !important;
	}
	
	.docDate-input-preventions {
		display: inline-block !important;
		width: calc(25% - 8px) !important;
		margin: 0 8px !important;
	}
	
	.amb-ins-comp-input {
		display: inline-block !important;
		width: calc(20% - 8px) !important;
		margin: 0 !important;
	}
	.preventions-ins-comp-input {
		display: inline-block !important;
		width: calc(30% - 8px) !important;
		margin: 0 !important;
	}
	
	.check-for-refferal-form .uin-input {
		display: inline-block !important;
		width: calc(23% - 8px) !important;
	}
	
	.check-for-refferal-form .docName-input {
		display: inline-block !important;
		width: calc(40% - 8px) !important;
	}
	
	.check-for-refferal-form .spec-input {
		display: inline-block !important;
		width: calc(36% - 8px) !important;
	}
	
	/* //////////////////////////// !!!!Inputs Styled////////////////////////////// */
	
	.ant-select-item-option-active:not(.ant-select-item-option-disabled),
	.ant-select-tree
		.ant-select-tree-treenode-active
		.ant-select-tree-node-content-wrapper,
	.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
		background-color: #acacac !important;
	}
	
	.ant-input-number-handler-wrap {
		opacity: 1 !important;
		border-left: 1px solid #000000 !important;
	}
	
	.ant-input-number:hover {
		border: 1px solid #8bc34a !important;
	}
	
	.ant-input-number-handler,
	.ant-input-number-handler-up-inner {
		color: black !important;
	}
	
	.ant-input-number-focused {
		box-shadow: none !important;
	}
	
	/* Removed arrows from number input */
	.ant-input-number-handler-wrap {
		display: none !important;
	}
	
	/* //////////////////////////////////////////// */
	
	/* Appointmen Details  Table ////////////////////////////////////// */
	
	.add-detail-btn {
		margin-bottom: 16px;
		border-radius: 20px;
		margin-top: -30px;
		margin-left: 170px;
		position: absolute;
		height: 25px;
	}
	
	.appointment-modal .ant-table {
		background-color: rgb(158 158 158 / 5%) !important;
	}
	
	.appointment-modal .ant-table-thead > tr > th {
		background: #868686;
	}
	
	/* .upload-form-item .ant-table {
						background-color: transparent !important;
						overflow-x: auto;
						overflow-y: hidden;
					}
					
					.upload-form-item .ant-table-thead > tr > th {
						display: none !important;
					} */
	
	.ant-table
		.ant-select-single:not(.ant-select-customize-input)
		.ant-select-selector {
		height: 25px;
		border-radius: 0px !important;
		border-color: rgba(0, 0, 0, 0.589);
		font-size: 14px;
		color: black;
	}
	
	.ant-table .ant-input,
	.ant-table .ant-input-number-input {
		font-size: 16px;
	}
	
	.ant-input-number-input,
	.ant-input-number {
		height: 25px;
	}
	.ant-table .ant-form-item-explain {
		min-height: 0px;
	}
	
	.ant-table input[type='text'],
	.ant-table input[type='number'],
	.ant-table input[type='email'],
	.ant-table textarea,
	.ant-table .ant-input-affix-wrapper {
		border-radius: 0px !important;
		padding: 2px 20px !important;
	}
	
	.hide-col {
		display: table-column;
	}
	
	/* ///////////////////////////////// */
	/* ////// Appointment Upload Table///////////////////// */
	
	/* .upload-form-item .ant-table-tbody > tr > td
					{
						border-left: 2px solid rgb(206, 206, 206) !important;
					} */
	
	.add-file-btn {
		margin-bottom: 16;
		border-radius: 20px;
		margin-top: -30px;
		margin-left: 200px;
		position: absolute;
		height: 25px;
	}
	
	/* .upload-form-item .ant-table-tbody > tr.ant-table-row:hover > td {
						background: transparent !important;
						border-left: 2px solid rgb(206, 206, 206) !important;
					} */
	
	/* .upload-form-item .ant-table input[type="text"],
					.upload-form-item .ant-table input[type="number"],
					.upload-form-item .ant-table input[type="email"],
					.upload-form-item .ant-table textarea,
					.upload-form-item .ant-table .ant-input-affix-wrapper {
						border-radius: 20px !important;
					}
					
					.upload-form-item
						.ant-table
						.ant-select-single:not(.ant-select-customize-input)
						.ant-select-selector {
						border-radius: 20px !important;
					} */
	
	/* .upload-form-item {
						margin-bottom: 0 !important;
					} */
	
	.ant-form-item {
		margin-bottom: 0 !important;
	}
	
	.ant-form-horizontal > .ant-row.ant-form-item {
		margin-bottom: 10px !important;
	}
	
	.home-grid-col-3 .ant-form-horizontal > .ant-row.ant-form-item {
		margin-bottom: 0px !important;
	}
	
	#price {
		padding: 2px !important;
	}
	
	.upload-btn {
		border: 1px solid;
		border-radius: 20px;
		text-align-last: center;
		background: darkgrey;
		color: white;
	}
	.upload-btn:hover {
		background: rgb(97, 97, 97);
		cursor: pointer;
	}
	/* /////////////////////////////////////////////////////// */
	
	.submit-referral-btn {
		float: right;
		margin: 0px;
	}
	
	.referral-print-div {
		font-size: 13px;
		color: black;
		margin-left: 30px;
	}
	
	.ant-select-selection-item {
		white-space: inherit !important;
	}
	
	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 15px !important;
		align-self: center;
	}
	
	.ant-popover-message-title {
		max-width: 400px;
	}
	
	/* .ant-popover{
						max-width: 400px !important;
					} */
	
	.ant-btn-sm {
		border-radius: 0px !important;
	}
	
	/* //////////////////// View Referral Page/////////////////////////////// */
	
	.view-referral-div {
		font-size: 13px;
		font-weight: 600;
		color: black;
		margin-left: 40px;
	}
	/* ////////////////////////////// Prior Approval Page//////////////// */
	
	/* Messanger/////////////////////////////////////////// */
	
	.rcw-conversation-container > .rcw-header {
		background: transparent linear-gradient(180deg, #8dc63f 0%, #599641 100%)
			no-repeat padding-box !important;
	}
	
	.rcw-message-text {
		color: black !important;
		font-size: 15px !important;
	}
	
	.rcw-client .rcw-message-text {
		background-color: #cbf9a8 !important;
	}
	
	.rcw-title {
		font-weight: bold;
		color: #fff;
	}
	
	.rcw-timestamp {
		font-size: 13px;
		margin-top: -10px;
	}
	
	.rcw-header span {
		padding-left: 10px;
		padding-right: 10px;
		font-size: 16px;
	}
	
	.rcw-launcher {
		background: transparent linear-gradient(180deg, #8dc63f 0%, #599641 100%)
			no-repeat padding-box !important;
	}
	
	/* /////////////////////////Notifications page//////////// */
	
	.ant-list-items {
		background: #fff8f8 !important;
	}
	
	.ant-list-item-meta-description {
		color: black !important;
		font-size: 15px !important;
	}
	
	.ant-list-item-meta-title {
		color: black !important;
		font-size: 14px !important;
	}
	
	/*  React-Chat-Widget/////////////////////////////////////////// */
	
	.rcw-widget-container {
		height: auto;
		width: auto;
	}
	.rcw-widget-container .rcw-conversation-container {
		width: 370px;
		margin-bottom: 10px;
	}
	.rcw-widget-container .rcw-launcher {
		margin-top: 0;
	}
	
	.disabled-chat .rcw-new-message,
	.disabled-chat .rcw-send {
		display: none;
	}
	
	/*                      */
	
	.add-service-correlation-btn {
		border-radius: 20px;
	}
	
	/* //////////////////////////Ambulatory Sheets */
	.scroll-table-service-correlations.ambSheets-table-grid,
	.scroll-table-service-correlations.labRes-table-grid {
		overflow-y: hidden !important;
	}
	
	/* /////////////////////////////// Insurance Portal Claim Table/////////////////////////// */
	
	.claim-table-striped tr:nth-child(even) {
		background-color: #afafaf;
	}
	
	.claims-table-select.ant-select-single:not(.ant-select-customize-input)
		.ant-select-selector {
		height: 25px !important;
	}
	
	.claims-table-select.ant-select-borderless .ant-select-selector {
		background-color: white !important;
	}
	
	/* XML file uploader */
	.xml-upload-bnt {
		border: 1px solid rgb(35, 66, 84);
		border-radius: 20px;
		cursor: pointer;
		margin: 10px 0px;
		padding: 3px 2px 2px;
		width: 30%;
		text-align-last: center;
		display: inline-block;
		background: rgb(35, 66, 84);
		color: white;
	}
	
	/*//////////////////////// Helpdesk///////////////////////////////// */
	
	.helpdesk-category-input {
		display: inline-block;
		width: 35%;
	}
	
	.helpdesk-module-input {
		display: inline-block;
		width: calc(32% - 10px);
		margin-left: 10px;
	}
	
	.helpdesk-email-input {
		display: inline-block;
		width: calc(30% - 15px);
		margin-left: 10px;
	}
	/* ///////////////////Media Query////////////////////////// */
	@media only screen and (max-width: 991px) {
		/* .ant-input-group-wrapper {
					float: left;
					margin-bottom: 10px;
				} */
	
		.ant-table {
			font-size: 13px;
			font-weight: 500;
		}
	
		.ant-table-tbody > tr > td,
		.ant-table tfoot > tr > th,
		.ant-table tfoot > tr > td {
			padding: 0px 3px;
			/* max-width: 100px; */
		}
		.editBtn {
			padding: 0;
		}
	
		.appointment-modal {
			width: 100% !important;
		}
		.date-range-tables {
			margin: 5px;
		}
	
		/* .space-table-head{
							width: 50% !important;
							padding: 10px 0px;
							display: block !important;
						} */
	}
	
	@media only screen and (max-width: 905px) {
		.document-type-div {
			display: none;
		}
	}
	
	@media only screen and (max-width: 870px) {
		/* 
					
						.spec-input {
							display: block !important;
							width: 100% !important;
							margin: 0 !important;
							margin-bottom: 14px !important;
						} */
		.uin-input,
		.egn-input,
		.docNumber-input,
		.check-for-refferal-form .docName-input,
		.check-for-refferal-form .spec-input,
		.helpdesk-category-input {
			display: block !important;
			width: 100% !important;
			margin-bottom: 10px !important;
		}
	
		.docName-input,
		.pacName-input,
		.email-input,
		.card-input,
		.docDate-input,
		.docDate-input-preventions,
		.amb-ins-comp-input,
		.preventions-ins-comp-input,
		.check-for-refferal-form .uin-input,
		.helpdesk-module-input {
			display: block !important;
			width: 100% !important;
			margin: 0 !important;
			margin-bottom: 10px !important;
		}
	
		.helpdesk-email-input {
			width: 93% !important;
			margin: 0 !important;
			margin-bottom: 10px !important;
		}
	
		.rcw-widget-container .rcw-conversation-container {
			height: 100vh;
			width: 96vw;
			margin-bottom: 0;
		}
	}
	
	@media only screen and (max-width: 770px) {
		.ant-input-group-wrapper {
			width: 100% !important;
		}
	
		.ant-table {
			overflow: scroll;
		}
	
		.ant-table table {
			width: auto;
		}
	
		body ::-webkit-scrollbar {
			width: 0px !important;
		}
	
		.scroll-table-service-correlations.ambSheets-table-grid,
		.scroll-table-service-correlations.labRes-table-grid {
			overflow-y: unset !important;
		}
	
		.patient-form-item,
		.issuer-form-item {
			width: 100%;
		}
	
		/* .date-range-tables {
							width: 50%;
						}
						.btn-groupe {
							width: 50% !important;
							margin: 10px 10px;
						}
						.search-input{
							width: 50% !important;
							margin: 0px 10px;
						} */
	}
	
	@media (max-width: 767px) {
		.ant-modal {
			max-width: calc(100vw - 16px);
			margin: 0px !important;
		}
	}
	@media only screen and (max-width: 575px) {
		h1.ant-typography,
		.ant-typography h1 {
			font-size: 25px;
		}
		.ant-table {
			font-size: 15px;
		}
	
		.ant-list-lg .ant-list-item {
			padding: 15px 14px !important;
			margin: 12px 0px !important;
		}
	
		.ant-list-item {
			display: block !important;
		}
		.date-range-tables {
			width: 100%;
			margin: 0px 0px;
		}
		.btn-groupe {
			width: 100% !important;
			margin: 10px 0px;
		}
		.search-input {
			width: 100% !important;
			margin: 10px 0px;
		}
	
		.space-table-head {
			display: block !important;
		}
	}
	
	@media only screen and (max-width: 480px) {
		.submit-referral-btn {
			width: 100% !important;
			margin-top: 10px !important;
		}
	
		.add-detail-btn {
			margin-bottom: 16px;
			border-radius: 20px;
			margin-top: 0px;
			margin-left: 0px;
			position: inherit;
			width: 100%;
		}
	
		.add-file-btn {
			margin-bottom: 16px;
			border-radius: 20px;
			margin-top: 0px;
			margin-left: 0px;
			position: inherit;
			width: 100%;
		}
	}
	
	@media only screen and (max-height: 600px) {
		.servise-correlation-table .ant-table-body {
			overflow-y: unset !important;
			max-height: unset !important;
		}
	}
	
	@media all {
		.page-break {
			display: none;
		}
	}
	
	@media print {
		html,
		body {
			font-family: 'Calibri' !important;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			background: white !important;
			height: initial !important;
			overflow: initial !important;
			page-break-before: always;
		}
	}
	
	@media print {
		.page-break {
			margin-top: 0.8rem;
			display: block;
			page-break-before: auto;
		}
	}
	
	@page {
		size: auto;
		margin: 15mm;
	}
	